
import { Options, Vue } from "vue-class-component";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import EmptyState from "@/app/ui/components/empty-list/index.vue";
import { AccountController } from "../../controllers/AccountController";
import debounce from "lodash/debounce";
import {
  dateToDateString,
  dateToTime,
  downloadCsvFromCsvContent,
  ellipsisString,
  formatPrice
} from "@/app/infrastructures/misc/Utils";
import { CodIncomeController } from "../../controllers/CodIncomeController";
import ModalWithdraw from "@/app/ui/views/balance/component/modal-withdraw.vue";
import { BalanceController } from "../../controllers/BalanceController";
import { CodIncomeData } from "@/domain/entities/CodIncome";
import {
  BalanceTransactionType,
  TransactionTypeGober
} from "@/domain/entities/BalanceTransaction";
import { BalanceTransactionControllers } from "@/app/ui/controllers/BalanceTransactionControllers";
import { FlagsPermissionCodBalance } from "@/feature-flags/flags-permission-cod-balance";

@Options({
  components: {
    Tooltip,
    EmptyState,
    ModalWithdraw
  },
  computed: {
    isVisibleViewDetail() {
      return FlagsPermissionCodBalance.permission_cod_balance_withdraw_view_detail.isEnabled();
    }
  }
})
export default class CodIncomeList extends Vue {
  async mounted() {
    this.options = await BalanceTransactionControllers.getBalanceTransactionType(
      "v1"
    ).then((res: TransactionTypeGober) => [
      new BalanceTransactionType({
        name: "Semua",
        value: ""
      }),
      ...res.balanceCodClient
    ]);
    await BalanceController.getBalance(true);
  }

  unmounted() {
    CodIncomeController.onReset();
  }

  get getCurrentRoute() {
    return this.$route;
  }

  get dataProfile() {
    return AccountController.accountData;
  }

  get isOpenModalTopup() {
    return CodIncomeController.isOpenModalTopup;
  }

  get codIncome() {
    return formatPrice(BalanceController.balanceData.walletBalanceCod);
  }

  get isOpenModalWithdraw() {
    return BalanceController.isOpenModalWithdraw;
  }

  openModalWithdraw() {
    if (!this.isDisableWithdraw) BalanceController.setOpenModalWithdraw(true);
  }

  closeModalWithdraw() {
    return BalanceController.setOpenModalWithdraw(false);
  }

  get isDisableWithdraw() {
    let isClientBranch = false;
    if (this.dataProfile.account_type === "client") {
      isClientBranch = this.dataProfile.account_type_detail.parentId !== 0;
    }
    return (
      BalanceController.balanceData.walletBalanceCod <= 0 || isClientBranch
    );
  }

  //tooltip
  isOpenTooltip = false;
  openTooltip = debounce((val: boolean) => {
    this.isOpenTooltip = val;
  }, 50);

  // search
  get search() {
    return CodIncomeController.search;
  }
  onSearch(value: string) {
    CodIncomeController.setSearch(value);
    CodIncomeController.setFirstPage();
    this.fetchCodIncomeList();
  }

  clearSearch() {
    CodIncomeController.setSearch("");
    this.fetchCodIncomeList();
  }

  // time range picker
  minDate: any = null;
  maxDate: any = null;
  isStartDate = true;
  setDay(day: any) {
    if (this.isStartDate) {
      this.minDate = new Date(
        new Date(day.id).setDate(new Date(day.id).getDate() - 89)
      );
      this.maxDate = new Date(
        new Date(day.id).setDate(new Date(day.id).getDate() + 89)
      );
      this.isStartDate = false;
    } else {
      this.endDate && (this.isStartDate = true);
    }
  }

  cancelRangePick() {
    if (!this.isStartDate) {
      this.minDate = null;
      this.maxDate = null;
      this.isStartDate = true;
    }
  }

  get startDate() {
    return CodIncomeController.filter.startDate;
  }

  get endDate() {
    return CodIncomeController.filter.endDate;
  }

  setDateRange(value: Array<any>) {
    this.minDate = null;
    this.maxDate = null;
    CodIncomeController.setStartDate(value[0]);
    CodIncomeController.setEndDate(value[1]);
    value[0] && value[1] && CodIncomeController.setFirstPage();
    value[0] && value[1] && this.fetchCodIncomeList();
  }

  // table
  get codIncomeList(): CodIncomeData[] {
    return CodIncomeController.codIncomeData.data;
  }

  get pagination() {
    return CodIncomeController.codIncomeData.pagination;
  }

  fetchCodIncomeList() {
    CodIncomeController.fetchCodIncomeList();
  }

  get isLoading() {
    return CodIncomeController.isLoading;
  }
  get errorCause() {
    return CodIncomeController.errorCause;
  }

  columns = [
    {
      name: "Genesis Transaksi ID",
      styleHead: "text-black-lp-300 w-56 text-left whitespace-no-wrap",
      render: (item: CodIncomeData) => {
        return `<div class="text-black-lp-300 capitalize text-left break-all">${item.genesisTransactionId}</div>`;
      }
    },
    {
      name: "No. STT",
      styleHead: "text-black-lp-300 w-48 text-left whitespace-no-wrap",
      render: (item: CodIncomeData) => {
        return `<div class="text-black-lp-300 capitalize text-left">${item.sttNumber}</div>`;
      }
    },
    {
      name: "Tipe",
      styleHead: "text-black-lp-300 w-40 text-left whitespace-no-wrap",
      render: (item: CodIncomeData) => {
        return `<p class="w-full break-all text-left">${this.getTrxNameByValue(
          item.type
        )}</p>`;
      }
    },
    {
      name: "Aktivitas",
      styleHead: "text-black-lp-300 w-28 text-left whitespace-no-wrap",
      render: (item: CodIncomeData) => {
        return `<div class="text-black-lp-300 capitalize overflow-ellipsis text-left">${item.activity}</div>`;
      }
    },
    {
      name: "Jumlah",
      styleHead: "text-black-lp-300 w-40 text-left whitespace-no-wrap",
      render: (item: CodIncomeData) => {
        return `<div class=" capitalize flex flex-col"><div class="font-medium text-left ${
          item.activity === "credit" ? "text-green-lp-100" : "text-red-lp-500"
        }">${item.activity === "credit" ? "+" : "-"}${formatPrice(
          item.amountTransaction
        )}</div></div>`;
      }
    },
    {
      name: "Saldo Terakhir",
      styleHead: "text-black-lp-300 w-40 text-left whitespace-no-wrap",
      render: (item: CodIncomeData) => {
        return `<div class="font-medium text-left">${formatPrice(
          item.lastBalance
        )}</div>`;
      }
    },
    {
      name: "Tanggal Transaksi",
      styleHead: "text-black-lp-300 w-48 text-left whitespace-no-wrap",
      render: (item: CodIncomeData) => {
        return `<div class="text-black-lp-300 capitalize overflow-ellipsis text-left">${this.formatDate(
          item.dateTransaction
        )}</div>`;
      }
    },
    {
      name: "Catatan",
      styleHead: "text-black-lp-300 w-64 text-left whitespace-no-wrap",
      render: (item: CodIncomeData) => {
        return `<div class="text-black-lp-300 capitalize text-left break-all">${
          item.note ? ellipsisString(item.note, 20) : "-"
        }</div>`;
      },
      vtooltip: (item: CodIncomeData) =>
        item.note?.length > 20 ? item.note : ""
    },
    {
      name: "Nama Bank",
      styleHead: "text-black-lp-300 w-48 text-left whitespace-no-wrap",
      render: (item: CodIncomeData) => {
        return `<div class="text-black-lp-300 capitalize text-left break-words">${
          item.bankName ? item.bankName : "-"
        }</div>`;
      }
    },
    {
      name: "Nama Rekening",
      styleHead: "text-black-lp-300 w-56 text-left whitespace-no-wrap",
      render: (item: CodIncomeData) => {
        return `<div class="text-black-lp-300 capitalize break-words text-left">${item.bankAccountName}</div>`;
      }
    },
    {
      name: "No. Rekening",
      styleHead: "text-black-lp-300 w-40 text-left whitespace-no-wrap",
      render: (item: CodIncomeData) => {
        return `<div class="text-black-lp-300 capitalize overflow-ellipsis text-left">${item.bankAccountNumber}</div>`;
      }
    },
    {
      name: "Genesis Referensi ID",
      styleHead: "text-black-lp-300 w-52 text-left whitespace-no-wrap",
      render: (item: CodIncomeData) => {
        return `<div class="text-black-lp-300 capitalize break-all text-left">${item.genesisReferenceId ||
          "-"}</div>`;
      }
    },
    {
      name: "Status",
      styleHead: "text-black-lp-300 w-36 text-left whitespace-no-wrap",
      render: (item: CodIncomeData) => {
        const status = item.status.toLowerCase();
        return `<div class="capitalize overflow-ellipsis text-left flex flex-row gap-x-1 font-medium ${this.handleClassTextColorTrxStatus(
          status
        )}">
            ${this.handleImgTrx(status)}
            ${item.status}
          </div>`;
      }
    }
  ];
  handleImgTrx(status: string) {
    if (status === "success") {
      return `<img class="w-3 max-w-none" src="${require("@/app/ui/assets/svg/status-success.svg")}" />`;
    }
    if (status === "failed") {
      return `<img class="w-3 max-w-none" src="${require("@/app/ui/assets/svg/status-failed.svg")}" />`;
    }
    return `<img class="w-3 max-w-none" src="${require("@/app/ui/assets/svg/status-request.svg")}" />`;
  }
  handleClassTextColorTrxStatus(status: string) {
    if (status === "success") {
      return "text-green-lp-100";
    }
    return status === "failed" ? "text-red-lp-750" : "text-yellow-lp-100";
  }

  formatDate(date: string) {
    return `${dateToDateString(date)}, ${dateToTime(date)}`;
  }

  // filter
  isOpenSelect = false;
  options = [] as BalanceTransactionType[];
  getTrxNameByValue(value: string) {
    const option = this.options.find((option: any) => {
      return option.value === value;
    });
    if (!option) {
      return value;
    }
    return option.name;
  }
  get advancedFilterData() {
    return CodIncomeController.advanceFilterData;
  }
  onSelect(value: any) {
    if (value.name === this.advancedFilterData.type.name) {
      CodIncomeController.setAdvanceFilterData({
        type: { name: "", value: "" }
      });
    } else {
      CodIncomeController.setAdvanceFilterData({
        type: { name: value.name, value: value.value }
      });
    }
    CodIncomeController.setFirstPage();
    this.fetchCodIncomeList();
  }

  async exportCod() {
    const resp = await CodIncomeController.exportCodTransaction({
      page: this.pagination.page,
      type: this.advancedFilterData.type.value,
      startDate: this.startDate
        ? new Date(this.startDate).toLocaleDateString("fr-CA")
        : "",
      endDate: this.endDate
        ? new Date(this.endDate).toLocaleDateString("fr-CA")
        : ""
    });

    if (resp.success) {
      downloadCsvFromCsvContent({
        fileName: "cod_income_mutation.csv",
        csvContent: resp.data
      });
    }
  }
}
