/* eslint-disable @typescript-eslint/camelcase */
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { container } from "tsyringe";
import { CodIncomePresenter } from "../presenters/CodIncomePresenter";
import { CodIncomeEntities } from "@/domain/entities/CodIncome";
import { Pagination } from "@/domain/entities/Pagination";
import { MainAppController } from "./MainAppController";
import { parsingErrorResponse } from "@/app/infrastructures/misc/Utils";

export interface CodIncomeState {
  isLoading: boolean;
}

@Module({
  namespaced: true,
  dynamic: true,
  store,
  name: "cod_income"
})
class CodIncomeStore extends VuexModule implements CodIncomeState {
  public isLoading = true;
  public advanceFilterData = {
    type: { name: "", value: "" }
  };
  public isError = false;
  public errorCause = "";
  public isOpenModalTopup = false;
  public search = "";
  public filter = {
    startDate: "",
    endDate: ""
  };
  public codIncomeData = new CodIncomeEntities(new Pagination(1, 30), []);

  @Action
  public getCodIncomeList(params: {
    page: number;
    limit: number;
    type: string;
    startDate: string;
    endDate: string;
    search: string;
  }) {
    this.setLoading(true);
    const presenter = container.resolve(CodIncomePresenter);
    presenter
      .getCodIncomeList(
        params.page,
        params.limit,
        params.type,
        params.startDate,
        params.endDate,
        params.search
      )
      .then((res: CodIncomeEntities) => {
        this.setCodIncomeList(res);
        this.setError(false);
        this.setErrorCause("");
      })
      .catch((error: any) => {
        this.setError(true);
        this.setErrorCause(error.response ? "server" : "internet");
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public exportCodTransaction(params: {
    page: number;
    type: string;
    startDate: string;
    endDate: string;
  }) {
    MainAppController.showLoading();
    MainAppController.closeErrorMessage();
    const presenter = container.resolve(CodIncomePresenter);
    return presenter
      .getExportCodTransaction(
        params.page,
        params.type,
        params.startDate,
        params.endDate
      )
      .then((res: string) => {
        return {
          data: res,
          success: true
        };
      })
      .catch((error: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Unduh Mutasi Gagal !", () => {
            MainAppController.closeErrorMessage();
            this.exportCodTransaction(params);
          })
        );
        return {
          data: "",
          success: false
        };
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public fetchCodIncomeList() {
    this.getCodIncomeList({
      page: this.codIncomeData.pagination.page,
      limit: this.codIncomeData.pagination.limit,
      type: this.advanceFilterData.type.value,
      startDate: this.filter.startDate
        ? new Date(this.filter.startDate).toLocaleDateString("fr-CA")
        : "",
      endDate: this.filter.endDate
        ? new Date(this.filter.endDate).toLocaleDateString("fr-CA")
        : "",
      search: this.search
    });
  }

  @Mutation
  public onReset() {
    this.codIncomeData.pagination = { page: 1, limit: 30 };
    this.advanceFilterData = { type: { name: "", value: "" } };
    this.filter = { startDate: "", endDate: "" };
    this.search = "";
  }

  @Mutation
  private setCodIncomeList(data: CodIncomeEntities) {
    this.codIncomeData = data;
  }

  @Mutation
  private setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  @Mutation
  public async setAdvanceFilterData(value: any) {
    this.advanceFilterData = value;
  }

  @Mutation
  public setSearch(val: string) {
    this.search = val;
  }

  @Mutation
  public setFilter(val: any) {
    this.filter = val;
  }

  @Mutation
  public setStartDate(val: any) {
    this.filter.startDate = val;
  }

  @Mutation
  public setEndDate(val: any) {
    this.filter.endDate = val;
  }

  @Mutation
  public setOpenModalTopup(boolean: boolean) {
    this.isOpenModalTopup = boolean;
  }

  @Mutation
  private setError(boolean: boolean) {
    this.isError = boolean;
  }

  @Mutation
  private setErrorCause(str: string) {
    this.errorCause = str;
  }

  @Action
  setFirstPage() {
    this.codIncomeData.pagination.page = 1;
  }
}

export const CodIncomeController = getModule(CodIncomeStore);
